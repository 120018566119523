// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-basket-js": () => import("./../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learningpacks-js": () => import("./../src/pages/learningpacks.js" /* webpackChunkName: "component---src-pages-learningpacks-js" */),
  "component---src-pages-order-failure-js": () => import("./../src/pages/order/failure.js" /* webpackChunkName: "component---src-pages-order-failure-js" */),
  "component---src-pages-order-paypalsuccess-js": () => import("./../src/pages/order/paypalsuccess.js" /* webpackChunkName: "component---src-pages-order-paypalsuccess-js" */),
  "component---src-pages-order-success-js": () => import("./../src/pages/order/success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

